<template>
  <div id="medTime-select">
    <v-combobox
      v-model="selectedMedTime"
      :items="medTimeList"
      item-text="time"
      outlined
      :label="label"
      clearable
      :multiple="multiple"
      dense
      :readonly="!isEdit"
      @change="selectMedTime(selectedMedTime)"
    >
    </v-combobox>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@core/utils/validation'

import medicationService from '@/services/MedicationService'
import useMedicationList from '@/store/useMedicationService'

export default {
  props: {
    medTimes: {
      type: Array,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Select Time',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const MEDICATION_APP = 'app-medication'

    // Register module
    if (!store.hasModule(MEDICATION_APP)) {
      store.registerModule(MEDICATION_APP, medicationService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEDICATION_APP)) store.unregisterModule(MEDICATION_APP)
    })

    const { medTimeList, fetchSiteMedTimes } = useMedicationList()

    const selectedMedTime = ref(props.medTimes)

    const log = msg => {
      //console.log(msg)
    }

    const selectMedTime = () => {
      if (props.multiple) {
        const medTimes = []
        selectedMedTime.value.forEach(medTime => {
          if (medTime.time === undefined) {
            medTimes.push(medTime)
          } else {
            medTimes.push(medTime.time)
          }
        })

        emit('med-time-select', medTimes)
      } else {
        emit('med-time-select', selectedMedTime.value)
      }
    }

    return {
      medTimeList,
      selectedMedTime,
      fetchSiteMedTimes,

      required,
      log,

      selectMedTime,
    }
  },
  mounted() {
    this.fetchSiteMedTimes()
  },
}
</script>
