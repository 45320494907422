<template>
  <div>
    <v-dialog
      v-model="isMedicationFormActive"
      persistent
      max-width="75%"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Medication Details</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="PatientScriptForm"
          >
            <v-row>
              <v-col
                cols="12"
                lg="6"
              >
                <v-text-field
                  v-model="patientScriptDTO.drugName"
                  label="Drug Name"
                  hint="Zyrtec"
                  :readonly="true"
                  :rules="[required]"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                lg="3"
              >
                <v-text-field
                  v-model="patientScriptDTO.rxcui"
                  label="RXCUI"
                  hint="RXNorm ID"
                  :readonly="true"
                  :rules="[required]"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                lg="3"
              >
                <v-text-field
                  v-model="patientScriptDTO.dEASchedule"
                  label="DEA Schedule"
                  hint="Schedule Drug Class"
                  :readonly="true"
                  :rules="[required]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                lg="2"
              >
                <v-text-field
                  v-model="patientScriptDTO.drugStrength"
                  label="Drug Strength"
                  hint="mg/1"
                  :readonly="true"
                  :rules="[required]"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                lg="3"
              >
                <v-text-field
                  v-model="patientScriptDTO.drugForm"
                  label="Drug Form"
                  hint="Tablet"
                  :readonly="true"
                  :rules="[required]"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                lg="4"
              >
                <v-combobox
                  v-model="pack"
                  dense
                  outlined
                  :items="patientScriptDTO.packs"
                  :rules="[required]"
                  item-text="description"
                  label="Select Package"
                  @change="updateNDC()"
                >
                </v-combobox>
              </v-col>

              <v-col
                cols="12"
                lg="3"
              >
                <v-combobox
                  v-model="route"
                  dense
                  outlined
                  :items="patientScriptDTO.routes"
                  :rules="[required]"
                  label="Select Route"
                  @change="updateRoute()"
                >
                </v-combobox>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                lg="4"
              >
                <script-type-select
                  :script-type="patientScriptDTO.scriptType"
                  @script-type-select="updateScriptType"
                ></script-type-select>
              </v-col>

              <v-col
                cols="12"
                lg="4"
              >
                <distribution-type-select
                  :distribution-type="patientScriptDTO.distributionType"
                  @distribution-type-select="updateDistributionType"
                ></distribution-type-select>
              </v-col>

              <v-col
                cols="12"
                lg="4"
              >
                <problem-select
                  :problem="patientScriptDTO.patientProblem"
                  :multiple="false"
                  @problem-select="updateProblem"
                ></problem-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                lg="3"
              >
                <v-text-field
                  v-model="patientScriptDTO.giveQuantity"
                  outlined
                  dense
                  clearable
                  label="Dose to Give"
                  hint="Drug strength to Distribute"
                  :readonly="!isEdit"
                  :rules="[required]"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                lg="3"
              >
                <v-text-field
                  v-model="patientScriptDTO.giveUnit"
                  outlined
                  dense
                  clearable
                  label="Quantity to give"
                  hint="e.g. 2"
                  :readonly="!isEdit"
                  :rules="[required]"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                lg="3"
              >
                <script-timing-select
                  :script-timing="patientScriptDTO.scriptTiming"
                  :is-edit="isEdit"
                  @timing-select="updateScriptTiming"
                ></script-timing-select>
              </v-col>

              <v-col
                cols="12"
                lg="3"
              >
                <prescriber-select
                  :prescriber="patientScriptDTO.prescriber"
                  @prescriber-select="updatePrescriber"
                ></prescriber-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                lg="5"
              >
                <v-textarea
                  v-model="patientScriptDTO.directions"
                  label="Directions"
                  hint="Take 200mg daily"
                  outlined
                  dense
                  clearable
                  rows="1"
                  :readonly="!isEdit"
                  :rules="[required]"
                  auto-grow
                ></v-textarea>
              </v-col>

              <v-col
                cols="12"
                lg="2"
              >
                <v-text-field
                  v-model="patientScriptDTO.refills"
                  :readonly="!isEdit"
                  :rules="[required]"
                  type="number"
                  outlined
                  dense
                  clearable
                  label="Refills"
                  hint="Number of refills"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                lg="2"
              >
                <v-text-field
                  v-model="patientScriptDTO.fillQuantity"
                  :readonly="!isEdit"
                  :rules="[required]"
                  type="number"
                  outlined
                  dense
                  clearable
                  label="Quantity"
                  hint="Total number of units in prescription"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                lg="3"
              >
                <billing-type-select
                  :billing-type="patientScriptDTO.billingType"
                  @billing-type-select="updateBillingType"
                ></billing-type-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                lg="12"
              >
                <distribution-schedule-select
                  :key="distType"
                  :schedule="patientScriptDTO.distributionSchedule"
                  :is-edit="isEdit"
                  :type="distType"
                  @distribution-schedule-select="updateDistributionSchedule"
                ></distribution-schedule-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                lg="2"
              >
                <v-switch
                  v-model="patientScriptDTO.slidingScale"
                  color="primary"
                  :value="patientScriptDTO.slidingScale = false"
                  label="Sliding Scale"
                  :readonly="!isEdit"
                >
                </v-switch>
              </v-col>

              <v-col
                cols="12"
                lg="2"
              >
                <v-switch
                    v-if="isEdit"
                    v-model="patientScriptDTO.isActive"
                    color="primary"
                    :readonly="!isEdit"
                  >
                  <template v-slot:label>
                    {{patientScriptDTO.isActive ? "Active" : "Inactive"}}
                  </template>
                  </v-switch>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="text-right">
                <v-btn
                  color="error darken-1"
                  type="reset"
                  text
                  @click="closeForm()"
                >
                  Close
                </v-btn>

                <v-btn
                  v-if="isEdit"
                  color="primary darken-1"
                  text
                  @click="submitForm(patientScriptDTO)"
                >
                  Save/Send Medication To Pharmacy
                </v-btn>

              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from '@/store'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { ref, onUnmounted } from '@vue/composition-api'
import medicationService from '@/services/MedicationService'
import useMedicationList from '@/store/useMedicationService'
import PrescriberSelect from '@/components/medication/PrescriberSelect.vue'
import ScriptTypeSelect from '@/components/medication/ScriptTypeSelect.vue'
import ProblemSelect from '@/components/problem/ProblemSelect.vue'
import DistributionTypeSelect from '@/components/medication/DistributionTypeSelect.vue'
import DistributionScheduleSelect from '@/components/medication/DistributionScheduleSelect.vue'
import BillingTypeSelect from '@/components/medication/BillingTypeSelect.vue'
import ScriptTimingSelect from '@/components/medication/ScriptTimingSelect.vue'
import { PatientScriptDTO } from '@/services/generated/api.generated.v1'

export default {
  components: {
    PrescriberSelect,
    ScriptTypeSelect,
    ProblemSelect,
    DistributionTypeSelect,
    DistributionScheduleSelect,
    BillingTypeSelect,
    ScriptTimingSelect,
  },
  model: {
    prop: 'isMedicationFormActive',
    event: 'update:is-medication-form-active',
  },
  props: {
    isMedicationFormActive: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    patientScriptDTO: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      PatientDTO: {
        directions: ''
      }
      
    }   
  },
  validations () {
    return {
      PatientDTO: {
        directions: { required }
      }
      
    }
    
  },
  setup(props, { emit }) {
    console.log('Patient Script', props.patientScriptDTO)

    const MEDICATION_APP = 'app-medication'

    // Register module
    if (!store.hasModule(MEDICATION_APP)) {
      store.registerModule(MEDICATION_APP, medicationService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEDICATION_APP)) store.unregisterModule(MEDICATION_APP)
    })

    const patientScriptForm = ref(null)
    const { postPatientScript, postStockMed } = useMedicationList()
    const menuID = ref(false)
    const menuLO = ref(false)
    const menuref = ref(null)
    const pack = ref(null)
    const route = ref(null)
    const distType = ref(null)
    const  v$ = useVuelidate()

    const save = d => {
      menuref.value.save(d)
    }

    const refreshParent = () => {
      emit('refetch-data')
    }

    const closeForm = () => {
      emit('update-dialog', 'render')
    }

    return {
      patientScriptForm,
      closeForm,
      refreshParent,

      postPatientScript,
      postStockMed,

      distType,
      required,
      menuID,
      menuLO,
      menuref,
      save,
      pack,
      route,
      v$: useVuelidate(),
    }
  },
  created() {
    if (this.patientScriptDTO.id == undefined) {
      this.patientScriptDTO.isActive = true
    }
  },
  methods: {
    submitStockMed() {
      const newStockMedDTO = {
        rxcui: this.patientScriptDTO.rxcui,
        nDC: this.patientScriptDTO.ndc,
        drugName: this.patientScriptDTO.drugName,
        drugStrength: this.patientScriptDTO.drugStrength,
        drugDose: this.patientScriptDTO.drugDose,
        drugForm: this.patientScriptDTO.drugForm,
        directions: this.patientScriptDTO.directions,
        quantity: this.patientScriptDTO.quantity,
      }
      this.postStockMed(newStockMedDTO)
    },
    log(msg) {
      console.log(msg)
    },
    submitForm() {
      this.patientScriptDTO.patientId =
        this.patientScriptDTO.patientId == null ? this.patientId : this.patientScriptDTO.patientId
        console.log("validation " + this.v$.$error)
       
        if (this.v$.$error) {
          alert('Please fill out all required inputs')
        }
        console.log('posting', this.patientScriptDTO)

        const isFormCorrect = this.v$.$validate()
        if (!isFormCorrect) return
      
      if (PatientScriptDTO.directions == null) {
        console.log("directions is null")
        this.postPatientScript(this.patientScriptDTO)
      }
      this.closeForm()
      this.refreshParent()
      alert('Medication Order sent successfully')
    },
    updatePrescriber(prescriber) {
      this.patientScriptDTO.prescriberId = prescriber.id
      //console.log('prescriber update', prescriber, this.patientScriptDTO)
    },
    updateScriptType(scriptType) {
      this.patientScriptDTO.scriptType = scriptType
      //console.log('script Type update', scriptType, this.patientScriptDTO)
    },
    updateDistributionType(distributionType) {
      this.patientScriptDTO.distributionType = distributionType
      this.distType = distributionType
      //console.log('Distribution Type update', distributionType, this.patientScriptDTO)
    },
    updateDistributionSchedule(schedule) {
      this.patientScriptDTO.distributionSchedule = schedule
      //console.log('Distribution Schedule update', schedule, this.patientScriptDTO)
    },
    updateBillingType(billingType) {
      this.patientScriptDTO.billingType = billingType
      //console.log('Billing Type update', billingType, this.patientScriptDTO)
    },
    updateScriptTiming(timing) {
      this.patientScriptDTO.scriptTimingId = timing.id
      //console.log('Script Timing update', timing, this.patientScriptDTO)
    },
    updateProblem(problem) {
      this.patientScriptDTO.problem = problem
      //console.log('problem update', problem, this.patientScriptDTO)
    },
    updateNDC() {
      this.patientScriptDTO.packs = this.pack
      //console.log('updateNDC', this.pack, this.patientScriptDTO)
    },
    updateRoute() {
      this.patientScriptDTO.drugRoute = this.route
      this.$delete(patientScriptDTO.routes, 0)
      //console.log('update Route', this.route, this.patientScriptDTO)
    },
  },
}
</script>
