<template>
  <div id="script-type-select">
    <v-combobox
      v-model="selectedScriptType"
      :items="scriptTypeList"
      :rules="[required]"
      outlined
      label="Select Prescription Type"
      clearable
      dense
      @change="selectScriptType(selectedScriptType)"
    >
    </v-combobox>
  </div>
</template>

<script>
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@core/utils/validation'

import medicationService from '@/services/MedicationService'
import useMedicationList from '@/store/useMedicationService'

export default {
  props: {
    scriptType: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const MEDICATION_APP = 'app-medication'

    // Register module
    if (!store.hasModule(MEDICATION_APP)) {
      store.registerModule(MEDICATION_APP, medicationService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEDICATION_APP)) store.unregisterModule(MEDICATION_APP)
    })

    const { scriptTypeList } = useMedicationList()

    const selectedScriptType = ref(props.scriptType)

    const log = msg => {
      //console.log(msg)
    }

    const selectScriptType = () => {
      emit('script-type-select', selectedScriptType.value)
    }

    return {
      scriptTypeList,
      selectedScriptType,

      required,
      log,

      selectScriptType,
    }
  },
}
</script>
