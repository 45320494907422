<template>
  <div id="billing-type-select">
    <v-combobox
      v-model="selectedBillingType"
      :items="billingTypeList"
      :rules="[required]"
      outlined
      label="Select Billing Type"
      clearable
      dense
      required
      @change="selectBillingType(selectedBillingType)"
    >
    </v-combobox>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@core/utils/validation'

import medicationService from '@/services/MedicationService'
import useMedicationList from '@/store/useMedicationService'

export default {
  props: {
    billingType: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const MEDICATION_APP = 'app-medication'

    // Register module
    if (!store.hasModule(MEDICATION_APP)) {
      store.registerModule(MEDICATION_APP, medicationService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEDICATION_APP)) store.unregisterModule(MEDICATION_APP)
    })

    const { billingTypeList } = useMedicationList()

    const selectedBillingType = ref(props.billingType)

    const log = msg => {
      //console.log(msg)
    }

    const selectBillingType = () => {
      emit('billing-type-select', selectedBillingType.value)
    }

    return {
      billingTypeList,
      selectedBillingType,

      required,
      log,

      selectBillingType,
    }
  },
}
</script>
